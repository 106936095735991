<template>
    <div class="page" v-if="isReady">
        <div class="info-wrapper">
            <h1>其他資訊</h1>

            <h2>{{ data.tc_title }}</h2>
            <div class="last-update-date">資料更新於{{ getLastUpdateDate(data.last_update_date) }}</div>

            <div class="detail" v-if="data.tc_description">
                <div class="ql-container ql-snow">
                    <div class="ql-editor">
                        <div v-html="data.tc_description" class="pre"></div>
                    </div>
                </div>
            </div>

            <div class="detail-pdf" v-if="data.pdf">
                <div v-for="(pdf, index) in data.pdf" :key="index" class="pdf-wrapper">
                    <div class="caption" v-if="pdf.caption !== ''">{{ pdf.caption }}</div>
                    <a :href="pdf.file" target="_blank">
                        <div class="pdf">
                            <embed :src="pdf.file + '#toolbar=0&navpanes=0&view=FitH'" type="application/pdf" />
                        </div>
                    </a>
                </div>
            </div>

            <div class="detail-photo" v-if="data.photo">
                <div v-for="(photo, index) in data.photo" :key="index" class="photo-wrapper">
                    <div class="caption" v-if="photo.caption !== ''">{{ photo.caption }}</div>
                    <div class="photo">
                        <img :src="photo.file" />
                    </div>
                </div>
            </div>
            <div class="detail-youtube" v-if="data.youtube_link">
                <div class="youtube">
                    <iframe :src="data.youtube_link" ></iframe>
                </div>
            </div>

            <!--
            <template v-if="data.need_sign === 'Y'">
                <div class="sign-panel" v-if="!isSigned">
                    <ul>
                        <li><input type="checkbox" v-model="confirm_read"> 本人已閱讀及明白以上內容。</li>
                    </ul>
                    <div class="error" v-if="confirm_error">請確定您已閱讀及明白以上內容</div>

                    <a @click="sign" class="btn-sign">確定簽署</a>
                </div>
                <div class="sign-panel" v-if="isSigned">
                    <div class="signed-message">
                        感謝您已於 {{ signed_date }} 確定簽署此通知內容。
                    </div>
                </div>
            </template>
            -->
        </div>
    </div>
</template>

<script>
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Fn from './Fn'
export default {
    mixins:[ Fn ],
    data() {
        return {
            isReady: false,
            data: [],
            confirm_read:null,
            confirm_error:false,
            isSigned: false,
            signed_date:null,
            timer:null
        }
    },
    mounted() {
        this.$root.updateNotification()
        const params = new FormData()
        params.append('id', this.$route.params.id)
        params.append('token', this.Auth.getToken())

        this.$axios
            .post(process.env.VUE_APP_API + 'other-detail' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
            .then(response => {
                let r = response.data
                console.log(r)
                if (r.success === true) {
                    this.data = r.data
                    this.isReady = true
                    /*
                    if (this.data.need_sign === 'Y') {
                        this.signed_date = this.data.signed_date
                        if (this.signed_date !== undefined && this.signed_date !== '' && this.signed_date !== null) {
                            this.isSigned = true
                        }
                    }
                    */
                } else {
                    this.$router.push('/other/')
                }
            })

        clearTimeout(this.timer)
        this.timer = setTimeout(this.logView, 1000)
    },
    methods: {
        sign () {
            this.confirm_error = false
            if (this.confirm_read !== true) {
                this.confirm_error = true
            } else {
                this.logView()
            }
        },
        logView() {
            const params = new FormData()
            params.append('id', this.$route.params.id)
            params.append('token', this.Auth.getToken())

            this.$axios
                .post(process.env.VUE_APP_API + 'other-view' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
                /*
                .then(response => {
                    let r = response.data
                    if (r.success === true) {
                        this.isSigned = true
                        let data = r.data
                        this.signed_date = data.signed_date
                        this.$root.updateNotification()
                    }
                })
                */
        }
    }
}
</script>

<style scoped lang="scss">
@import 'Page.scss';
h2 { margin-top:50px; }
.ql-container.ql-snow {
    border:0px;
    :deep(h1) { color:inherit; text-shadow:inherit; letter-spacing:inherit; }
    :deep(h2) { color:inherit; text-shadow:inherit; letter-spacing:inherit; }
    :deep(h3) { color:inherit; text-shadow:inherit; letter-spacing:inherit; }
}
</style>
